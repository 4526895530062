import React, { FC } from "react"
import { Stack, Text, Box, Center } from "../common"
import { Layout } from "../components/Layout"
import { ZHelmet } from "../components/ZHelmet"
import { CityMobileLayout } from "../components/CityMobileLayout"

const MobilePrivacyPolicy: FC = props => {
  return (
    <CityMobileLayout>
      <ZHelmet
        title="Privacy policy | best NBFC | Personal Loan | India"
        description="Get complete detail about privacy policy of Zavron Finserv best NBFC or persoanl provider in India just visit page to know more about the Zavron Finserv"
      />
      <Box>
        <Center gutters="var(--s2)" maxWidth="var(--max-width)">
          <Stack space="var(--s2)">
            <Text style={{ textAlign: "center" }} variant="h3">
              Privacy Policy
            </Text>
            <Stack space="var(--s0)">
              <Text variant="p">
                This PRIVACY POLICY (“Policy”) has been drafted in accordance with the Information Technology Act, 2000 and the Rules there under as applicable and the amended provisions pertaining to electronic records in various statutes as amended by the Information Technology Act, 2000. This electronic record is generated by a computer system and does not require any physical or digital signatures.
                ZAVRON FINANCE PRIVATE LIMITED, a Company within the meaning of Companies Act, 2013, having registered office at 125, Maharajbagh Road, Sitabuldi, Nagpur, Maharashtra, 440012, India (hereinafter shall be referred to as "Company" or "us" or "we") is an NBFC registered with the Reserve Bank of India (“RBI”) and is the licensed User of the mobile application named ZinCash ("App") and website at www.zavronfinserv.com ("Website") (App and Website are hereinafter together referred to as the "Platform").
              </Text>

              <Text variant="p">
                This Policy Describes Our Policies And Procedures For The Collection, Use, Storage, Processing, Disclosure And Protection Of Any Information, Including, But Not Limited To, Business Or Personal Information Provided By You As A User (“you/r” Or “user”) While Using The Platform. User Shall Mean Any Person/ Persons, Who Visits, Uses, Deals With And/ Or Transacts Through The Platform Or Avails Our Services And Includes A Browser.
                This Policy Constitutes A Legal Agreement Between You, As The User Of The Platform, And The Company, As The Licensed User Of The Platform. You Must Be A Natural Person Who Is At Least 21 Years Of Age.
                By Visiting/ Accessing The Platform, Through The Website Or The App And Voluntarily Providing Us With Information (Personal And/ Or Non- Personal) For The Products Or Otherwise, You Are Consenting To Our Use Of It In Accordance With This Policy.
              </Text>

              <Text variant="p">
                This Policy Does Not Apply To Third-party Websites / Applications
                That Are Connected Via Links To The Platform. If You Do Not Agree With The Terms And Conditions Of This Policy, Please Do Not Proceed Further To Use/ Access This Platform.
                Your Use Of The Platform Will Be Governed By This Policy As Applicable To The Platform Together With All Policies, Notices, Guidelines, Disclaimers That Are Published And Shared With You From Time To Time Which Are Incorporated Herein By Way Of Reference Including But Not Limited To Our Terms & Conditions And Such Other Terms As May Be Applicable To You In Your Capacity As A User Of The Website.
              </Text>

              <Text variant="p">
                This Policy Shall Be Enforceable Against You In The Same Manner As Any Other Written Agreement.
                This Policy Is A Part Of The Platform. Terms & Conditions And All Capitalized Terms Under This Policy That Have Not Been Specifically Defined Herein Shall Have The Meaning As Ascribed To It Under The Platform Terms & Conditions. We May Update This Policy From Time To Time. You Must Periodically Review The Policy For The Latest Information On Our Privacy Practices.
              </Text>

              <Text variant="p">
                <Text variant="h4">1. COLLECTION OF INFORMATION</Text>
                During your use of the Platform, we will collect information and data including but not limited to your personal information and sensitive personal information/ data ("SPD & I") as defined under the Information Technology (Reasonable security practices and procedures and sensitive personal data or information) Rules, 2011. You hereby provide us explicit consent to collect the following data from you as a data provider which also includes SPD & I:
              </Text>

              <Text variant="p">
                {`\u00A0`} 1. Your name, address, date of birth, contact information, PAN number, name of the bank where you have your primary account, bank statements, income tax returns, pay slips etc.
              </Text>

              <Text variant="p">
                {`\u00A0`} 2. Details regarding your employment, including, details of income, name of the current employer, details of the office address, details regarding the years/months completed with the current employer.
              </Text>

              <Text variant="p">
                {`\u00A0`} 3. Credit-related information that is collected from other sources like the credit bureau.
              </Text>

              <Text variant="p">
                {`\u00A0`} 4. Know Your Customer data such as Valid Address proof, PAN, photo, etc.
              </Text>

              <Text variant="p">
                {`\u00A0`} 5. Your unique identifiers such as username and password, preferences information and transaction history.
              </Text>

              <Text variant="p">
                {`\u00A0`} 6. Any other information that the Company is required to collect as per its specific mandate from Reserve Bank of India or as a requirement under applicable law in India.
              </Text>

              <Text variant="p">
                {`\u00A0`} 7. Information from you electronically - like the pages viewed, how you navigate through the Platform and interact with the Platform, etc.

              </Text>

              <Text variant="p">
                {`\u00A0`} 8. We may also automatically receive and collect certain anonymous information in standard usage logs through the web server, including mobile- identification information obtained from the equivalent of “cookies” sent to the App, including mobile network information, standard web log information, traffic to and from our app, tracking inside the App and any other available information, from: An IP Address, assigned to the device used by the User. The domain server through which the User accesses the App and the functions and features therein. and The type of device used by the User (“Device”)

              </Text>

              <Text variant="p">
                {`\u00A0`}9. In order to enhance our ability to provide valuable services and experiences to the User, We may: (i) automatically receive, collect and analyse your location information which may be accessed through a variety of methods including,
                inter alia, GPS, IP Address, and cell tower location. and (ii) collect information pertaining to your Device and your usage thereof.
                As detailed above, we also collect your personal information, including financial information, from the registration or other completed forms / questionnaires that You provide to Us. We will also receive your personal information, including financial information, from documents that you may provide to Us and/or from documents like the credit report that You authorize Us to obtain on Your behalf from credit information companies.
                The information We collect about You will depend on the products and services we offer, on an ongoing basis. The information collected from You will be used in order to effectively provide Services to You. If You do not allow Us to collect all the information, We may not be able to deliver all of these services effectively. You hereby provide us explicit consent to use the data specified above as per the terms of this Policy.
              </Text>


              <Text variant="p">
                <Text variant="h4">2. USE OF INFORMATION</Text>
                When the User registers with the App, We will use the information supplied by the User to pull a Credit Report from Credit Bureaus and identify verification services to facilitate banks and other financial institutions to avaluate the User’s loan request in the context of the User’s complete financial situation.
                You hereby provide explicit consent to Us for the use of Your information provided (including SPD & I) provided by You to Us (as specified above) and to share such information with third parties for the following purposes:
              </Text>

              <Text variant="p">
                {`\u00A0`} 1. Monitor, improve and administer our Platform
              </Text>

              <Text variant="p">
                {`\u00A0`} 2. Manage our risks including the risk of fraud that may be committed against us or our partners.
              </Text>

              <Text variant="p">
                {`\u00A0`} 3. Analyze how the Platform is used, diagnose service or technical problems and maintain security.
              </Text>

              <Text variant="p">
                {`\u00A0`} 4. Send communications notifications, information regarding the products or services requested by You or process queries and applications that you have made on the Platform, including marketing and promotion of our Services or our Platform by way of tele-messages or emails or calls.
              </Text>

              <Text variant="p">
                {`\u00A0`} 5. Manage our relationship with you and provide You with or inform you about other products or services we think you might find of some use.
              </Text>

              <Text variant="p">
                {`\u00A0`} 6. Conduct data analysis in order to improve the Services provided to the User.
              </Text>

              <Text variant="p">
                {`\u00A0`} 7. Use the User information in order to comply with country laws and regulations.
              </Text>

              <Text variant="p">
                {`\u00A0`} 8. To conduct KYC based on the information shared by the User for the provision of Services and availing the Product. This would include conducting analysis of your bank statements and eKYC. Company, may directly or through third parties, conduct the KYC on its customers. In the event the KYC as details under this clause is being conducted by its third-party service providers, the Company shall share Your relevant information with such third-party service providers for availing the said services.
              </Text>

              <Text variant="p">
                {`\u00A0`} 9. Use the User information in other ways permitted by law.
              </Text>

              <Text variant="p">
                {`\u00A0`}10. Use the information obtained from Your Device to control risk, detect fraud, and provide better services to You.
              </Text>

              <Text variant="p">
                {`\u00A0`}11. We will use and retain Your information for such periods as necessary to comply with Our legal obligations, resolve disputes, and enforce Our agreements.
              </Text>


              <Text variant="p">
                <Text variant="h4">3. DISCLOSURE OF INFORMATION</Text>
                We will not use User information for any purpose other than in connection with the App. ZFPL will not rent, sell or share User information and will not disclose any of the User’s personally identifiable information to third parties, unless:
              </Text>

              <Text variant="p">
                {`\u00A0`} • it is pursuant to obtaining the User’s permission
              </Text>

              <Text variant="p">
                {`\u00A0`} • it is in connection with disclosure to financial institutions and banks, in which case, the User hereby gives his express permission
              </Text>

              <Text variant="p">
                {`\u00A0`} • it is in connection with the services being rendered through the App
              </Text>

              <Text variant="p">
                {`\u00A0`} • it is to help investigate, prevent or take action regarding unlawful and illegal activities. suspected fraud, potential threat to the safety or security of any person, violations of ZinCash’s Terms & Conditions, or as defence against legal claims
              </Text>

              <Text variant="p">
                {`\u00A0`} • it is a case of special circumstances such as compliance with court orders, requests/order, notices from legal authorities or law enforcement agencies compel us to make such disclosure
              </Text>

              <Text variant="p">
                {`\u00A0`} • it forms part of the information ZinCash shares with advertisers on an aggregate basis.
              </Text>


              <Text variant="p">
                <Text variant="h4">4. STORAGE AND SECURITY OF INFORMATION</Text>
                We store and process Your personal information, including the information received from Your Device on Amazon Cloud Servers and other secure cloud service providers. All information received by Us, either directly from You or through Your Device is protected and secured by Us. We adopt multiple safeguards to protect the security of the information and data provided by You like firewalls and data encryption using SSL, and information access authorization controls.
                We use reasonable safeguards to preserve the integrity and security of Your information against loss, theft, unauthorized access, disclosure, reproduction, use or amendment. To achieve the same, We use reasonable security practices and procedures as mandated under applicable laws for the protection of Your information. Information You provide to Us may be stored on Our secure servers located within India.
                However, You understand and accept that there’s no guarantee that data transmission over the Internet will be completely secure and that any information that You transmit to Us is at Your own risk. We assume no liability whatsoever for any disclosure of information due to errors in transmission, unauthorized third-party access to our Platform and data bases or other acts of third parties, or acts or omissions beyond Our reasonable control and You shall not be entitled to hold the Company responsible for any breach of security.
              </Text>


              <Text variant="p">
                <Text variant="h4">5. PHISHING</Text>
                "Phishing" usually occurs when users of a website are induced by an individual/entity into divulging sensitive personal data by using fraudulent websites and/ or e-mail addresses. In the event of You providing information to a website or responding to an e-mail which does not belong to Us or is not connected with Us in any manner, You will be a victim of Phishing. We do not send e-mails requesting a user for payment information, user name or passwords. However, We may verify the user name, password etc. provided by You from time to time.
              </Text>

              <Text variant="p">
                <Text variant="h4">6. SEVERABILITY AND EXCLUSION</Text>
                We have taken every effort to ensure that this Policy adheres with the applicable laws. The invalidity or unenforceability of any part of this Policy shall not prejudice or affect the validity or enforceability of the remainder of this Policy. This Policy does not apply to any information other than the information collected by Us through the Platform. This Policy shall be inapplicable to any unsolicited information You provide Us through the Platform or through any other means. This includes, but is not limited to, information posted in any public areas of the Platform. All unsolicited information shall be deemed to be non- confidential and the Company shall be free to use and/ or disclose such unsolicited information without any limitations.
              </Text>


              <Text variant="p">
                <Text variant="h4">7. GOVERNING LAW AND DISPUTE RESOLUTION</Text>
                This Policy shall be governed by and construed in accordance with the laws of the Republic of India. The courts at Nagpur, India shall have exclusive jurisdiction in relation to any disputes arising out of or in connection with this Policy.

              </Text>

              <Text variant="p">
                <Text variant="h4">8. NO WAIVER</Text>
                The rights and remedies available under this Policy may be exercised as often as necessary and are cumulative and not exclusive of rights or remedies provided by law. It may be waived only in writing. Delay in exercising or non-exercise of any such right or remedy does not constitute a waiver of that right or remedy, or any other right or remedy.
              </Text>

              <Text variant="p">
                <Text variant="h4">9. CONTACT US</Text>
                If You require any information or clarification regarding the use of Your personal information or this Privacy Policy, You can contact us at info@zavronfinserv.com If You have grievance with respect to use of Your personal information, please contact us at support@zavronfinserv.com The Company is a company based in India. By browsing/using the Platform, You hereby acknowledge that the Company is not responsible or liable in any manner to comply with any local laws of Your territory except India.`
              </Text>




            </Stack>
          </Stack>
        </Center>
      </Box>
    </CityMobileLayout>
  )
}

export default MobilePrivacyPolicy