import React, { FC, useState } from "react"
import {
  Center,
  Text,
  Cluster,
  Box,
  Stack,
  Switcher,
} from "../common"
import { Sticky } from "react-sticky"
import { Drawer } from "antd"
import { PersonalLoan } from "../common/icons/PersonalLoan"
import { css } from "@emotion/core"
import { BusinessLoan } from "../common/icons/BusinessLoan"
import { UsedCarLoan } from "../common/icons/UsedCarLoan"
import { TwoWheeler } from "../common/icons/TwoWheeler"
import { Link } from "gatsby"
import { LinkHoverCSS } from "../common/utils"
import { HamburgerIcon } from "../common/icons/HamburgerIcon"

const PRODUCTS = [
  {
    icon: <PersonalLoan />,
    title: "Personal Loan",
    link: "/products/personal-loan",
  },
  {
    icon: <UsedCarLoan />,
    title: "Used Car Loan",
    link: "/products/used-car-loan",
  },
  {
    icon: <BusinessLoan />,
    title: "Business Loan",
    link: "/products/business-loan",
  },
  {
    icon: <TwoWheeler />,
    title: "Two-wheeler Loan",
    link: "/products/two-wheeler-loan",
  },
]

const MOBILE_HEADER_LINKS = [
  {
    title: "Home",
    url: "/",
  },
  ...PRODUCTS.map(product => ({
    title: product.title,
    url: product.link,
  })),
  {
    title: "Blogs",
    url: "/blogs",
  },
  {
    title: "Pay EMI",
    url: "/pay-emi",
  },
  {
    title: "Apply For Loan",
    url: "/apply",
  },
  {
    title: "About",
    url: "/about-us",
  },
  {
    title: "FAQs",
    url: "/faqs",
  },
]

export const ZincashHeader: FC<any> = props => {
  const [visible, showDrawer] = useState(false)
  const appstoreIconwidth = "60"
  const appStoreheight = "20"


  return (
    <>
      <Drawer
        onClose={e => showDrawer(visible => !visible)}
        closable
        visible={visible}
      >
        <Stack space="var(--s1)">
          {MOBILE_HEADER_LINKS.map(item => (
            <Link activeClassName="current-active-link" to={item.url}>
              <Text css={LinkHoverCSS} style={{ color: "black" }} variant="p">
                {item.title}
              </Text>
            </Link>
          ))}
          {/* <Cluster space="var(--s1)">
             <Link to="https://apps.apple.com/in/app/zincash/id1581920928" target="_blank">
                    <img
                      width="100"
                      src="https://res.cloudinary.com/dhoiqmk4x/image/upload/v1630934856/apple_jrlute.png"
                      alt="logo"
                      css={css`
                        @media screen and (max-width: 600px) {
                          margin-bottom: 0 !important;
                        }
                      `}
                    />
                  </Link>
                  <Link to="https://play.google.com/store/apps/details?id=com.zavrofinserv.customer" target="_blank">
                    <img
                      width="100"

                      src="https://res.cloudinary.com/dhoiqmk4x/image/upload/v1630934494/android_ma9ttf.png"
                      alt="logo"
                      css={css`
                        @media screen and (max-width: 600px) {
                          margin-bottom: 0 !important;
                        }
                      `}
                    />
                  </Link>
                </Cluster> */}
        </Stack>
      </Drawer>

      <Sticky
        css={css`
          @media screen and (min-width: 900px) {
            display: none;
          }
        `}
      >
        {({ style, distanceFromTop }) => (
          <Box
            padding="var(--s0) var(--s1)"
            style={{
              ...style,
              zIndex: 9,
              backgroundColor: "white",
              boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.12)",
            }}
            css={css`
              @media screen and (min-width: 900px) {
                display: none;
              }
            `}
          >
            <Switcher max={5} threshold="0px" space="var(--s0)">
              <Box>
                <Link to="/zincash">
                  <img
                    src="https://res.cloudinary.com/dhoiqmk4x/image/upload/v1630935001/ZinCash_gcdmr1.png"
                    alt="black-logo"
                    width="90"
                  />
                </Link>
              </Box>

              <Box><div></div></Box>

              <Box>

                <Link to="https://play.google.com/store/apps/details?id=com.zavrofinserv.customer" target="_blank">
                  <img
                    height={appStoreheight}
                    width={appstoreIconwidth}
                    src="https://res.cloudinary.com/dhoiqmk4x/image/upload/v1630934494/android_ma9ttf.png"
                    alt="logo"
                    css={css`
                        @media screen and (max-width: 600px) {
                          margin-bottom: 0 !important;
                        }
                      `}
                  />
                </Link>
                {/* </Cluster> */}
              </Box>
              <Box>
                {/* <Cluster space="var(--s0)"> */}
                <Link to="https://apps.apple.com/in/app/zincash/id1581920928" target="_blank">
                  <img
                    height={appStoreheight}
                    width={appstoreIconwidth}
                    src="https://res.cloudinary.com/dhoiqmk4x/image/upload/v1630934856/apple_jrlute.png"
                    alt="logo"
                    css={css`
                        @media screen and (max-width: 600px) {
                          margin-bottom: 0 !important;
                        }
                      `}
                  />
                </Link>
              </Box>
              <Box
                css={css`
                  display: flex;
                  cursor: pointer;
                  align-items: center;
                  justify-content: flex-end;
                `}
                onClick={e => showDrawer(visible => !visible)}
              >
                <HamburgerIcon />
              </Box>
            </Switcher>
          </Box>
        )}
      </Sticky>

      <Sticky
        css={css`
          @media screen and (max-width: 899px) {
            display: none;
          }
        `}
      >
        {({ style, distanceFromTop }) => (
          <Box
            style={{
              ...style,
              zIndex: 9,
              backgroundColor: "white",
              paddingTop: "var(--s0)",
              paddingBottom: "var(--s-1)",
              boxShadow:
                distanceFromTop !== 0
                  ? "0px 1px 3px rgba(0, 0, 0, 0.12)"
                  : "none",
            }}
            css={css`
              @media screen and (max-width: 899px) {
                display: none;
              }
            `}
          >
            <Center gutters="var(--s2)" maxWidth="var(--max-width)">
              <Cluster space="var(--s0)" align="center" justify="space-between">
                <Cluster space="var(--s3)">
                  <Link to="/zincash">
                    <img
                      width="135"
                      src="https://res.cloudinary.com/dhoiqmk4x/image/upload/v1630935001/ZinCash_gcdmr1.png"
                      alt="logo"
                      css={css`
                        @media screen and (max-width: 600px) {
                          margin-bottom: 0 !important;
                        }
                      `}
                    />
                  </Link>
                  <Cluster space="var(--s4)">
                    <Link to={props.redirectToZinCash ? "/" : "/zincash"}>
                      <Text variant="h4" style={{ color: '#666666', fontWeight: 'bold' }}>Home</Text>
                    </Link>
                    <Link to="/about-us">
                      <Text variant="h4" style={{ color: '#666666', fontWeight: 'bold' }}>About Us</Text>
                    </Link>
                    <Link to="/blogs">
                      <Text variant="h4" style={{ color: '#666666', fontWeight: 'bold' }}>Blogs</Text>
                    </Link>


                  </Cluster>
                </Cluster>
                <Cluster space="var(--s1)">
                  <Link to="https://play.google.com/store/apps/details?id=com.zavrofinserv.customer" target="_blank">

                    <img
                      width="130"
                      src="https://res.cloudinary.com/dhoiqmk4x/image/upload/v1630934494/android_ma9ttf.png"
                      alt="logo"
                      css={css`
                        @media screen and (max-width: 600px) {
                          margin-bottom: 0 !important;
                        }
                      `}
                    />
                  </Link>
                  <Link to="https://apps.apple.com/in/app/zincash/id1581920928" target="_blank">
                    <img
                      width="130"
                      src="https://res.cloudinary.com/dhoiqmk4x/image/upload/v1630934856/apple_jrlute.png"
                      alt="logo"
                      css={css`
                        @media screen and (max-width: 600px) {
                          margin-bottom: 0 !important;
                        }
                      `}
                    />
                  </Link>
                </Cluster>
              </Cluster>
            </Center>
          </Box>
        )}
      </Sticky>
    </>
  )
}
