import React, {FC} from 'react'
import {Center, Stack, Switcher, Text, Cluster, Box} from '../common'
import {FacebookIcon} from '../common/icons/Facebook'
import {InstagramIcon} from '../common/icons/Instagram'
import {TwitterIcon} from '../common/icons/Twitter'
import {Link} from 'gatsby'
export const CityFooter: FC = props => {
  return (
    <Box padding="var(--s3) 0" backgroundColor="var(--primary-color)">
      <Center gutters="var(--s2)" maxWidth="var(--max-width)">
        <Switcher threshold="var(--min-width)" space="var(--s2)" max={5}>
          <Box style={{flexGrow: 2}}>
            <Stack space="var(--s0)">
              <img
                width="135"
                src="https://res.cloudinary.com/dhoiqmk4x/image/upload/v1595829219/Asset_3_2x_1_tonwt7.png"
                alt="logo"
              />
              <Text style={{color: 'white'}} variant="p">
                LICENSED BY RBI
              </Text>
              <Stack>
                <Text style={{color: 'white'}} variant="p">
                  ZAVRON FINANCE PVT LTD
                </Text>
                <Text style={{color: 'white'}} variant="p">
                  RBI License no.:- N-13.02268
                </Text>
                <Text style={{color: 'white'}} variant="p">
                  CIN:- U67100MH2017PTC292183
                </Text>
                {/* <Text style={{ color: "white" }} variant="p">
                  Member of EXPERIAN
                </Text> */}
              </Stack>
            </Stack>
          </Box>
          <Box>
            <Stack space="var(--s-1)">
              <Link to="/">
                <Text style={{color: 'white'}} variant="p">
                  Home
                </Text>
              </Link>
              <Link to="/about-us">
                <Text style={{color: 'white'}} variant="p">
                  About us
                </Text>
              </Link>
              <Link to="/apply">
                <Text style={{color: 'white'}} variant="p">
                  Contact us
                </Text>
              </Link>
              <Link to="/faqs">
                <Text style={{color: 'white'}} variant="p">
                  Careers
                </Text>
              </Link>
            </Stack>
          </Box>
          <Box>
            <Stack space="var(--s-2)">
              <Link to="/">
                <Text style={{color: 'white'}} variant="p">
                  Products
                </Text>
              </Link>

              <Link to="/apply">
                <Text style={{color: 'white'}} variant="p">
                  Apply Loan
                </Text>
              </Link>

              <Link to="/pay-emi">
                <Text style={{color: 'white'}} variant="p">
                  Pay EMI
                </Text>
              </Link>

              <Link to="/faqs">
                <Text style={{color: 'white'}} variant="p">
                  FAQ's
                </Text>
              </Link>


            </Stack>
          </Box>
          <Box>
            <Stack space="var(--s-2)">
              <Link to="/privacy-policy">
                <Text style={{color: 'white'}} variant="p">
                  Privacy Policy
                </Text>
              </Link>
              <Link to="/terms-and-conditions">
                <Text style={{color: 'white'}} variant="p">
                  Terms & Conditions
                </Text>
              </Link>

            </Stack>
          </Box>
          <Box>
            <Stack space="var(--s1)">
              <Text style={{color: 'white'}} variant="p">
                Follow us:
              </Text>
              <Cluster space="var(--s0)">
                <a
                  href="https://www.facebook.com/Zavronfinserv/"
                  target="_blank">
                  <FacebookIcon />
                </a>

                <a
                  href="https://instagram.com/zavronfinserv?igshid=k7l8rb8zx0z6"
                  target="_blank">
                  <InstagramIcon />
                </a>

                <a
                  href="https://twitter.com/zavronfinance?s=12"
                  target="_blank">
                  <TwitterIcon />
                </a>

              </Cluster>
            </Stack>
          </Box>
        </Switcher>
      </Center>
    </Box>
  )
}
