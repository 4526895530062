import React, { FC } from "react"
import { StickyContainer } from "react-sticky"
import { Stack } from "../common"
import { ZincashHeader } from "./ZincashHeader"
import { CityFooter } from "./CityFooter"

const CityMobileLayout: FC = ({ children }) => {
  return (
    <StickyContainer>
      <ZincashHeader />
      <Stack style={{ paddingTop: "var(--s1)" }} space="var(--s3)">
        {children}
        <CityFooter />
      </Stack>
    </StickyContainer>
  )
}

export { CityMobileLayout }
